import React from "react"
import { Link } from "gatsby"
import Navigation from "./navigation"
// import CookieBanner from './cookie-banner';
import { rhythm, scale } from "../utils/typography"

const Layout = ({ title, children }) => {

  return (
    <div>
      {/* <CookieBanner></CookieBanner> */}
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(30),
          padding: `${rhythm(0.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <Navigation />
      </div>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>
          <h1
            style={{
              ...scale(1.5),
              marginBottom: rhythm(1.5),
              marginTop: 0,
            }}
          >
            <Link
              activeClassName="active"
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h1>
        </header>
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>, <Link to={`/imprint`}>Imprint</Link>

        </footer>
      </div>
    </div>
  )
}

export default Layout
