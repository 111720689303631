/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import * as navigationStyles from "./navigation.module.scss"
import {socialLinks} from "./navigation.module.scss"
import { Link } from "gatsby"
import { FaInstagram, FaYoutube, FaTwitter, FaPatreon } from "react-icons/fa"
import {  } from "./layout.module.scss"
import { useStaticQuery, graphql } from "gatsby"

const Navigation = () => {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          social {
            twitter
            youtube
            patreon
            instagram
          }
        }
      }
    }
  `)

  const { social } = data.site.siteMetadata

  const hideMenu = () => {
    setBurgerMenuOpen(false)
  }

  const toggleMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen)
  }

  return (
    <div
      className={`${navigationStyles.navigation} ${
        burgerMenuOpen ? navigationStyles.burgerOpen : ""
      }`}
    >
      <button
        alt=""
        aria-label="open menu"
        onClick={() => toggleMenu()}
        onKeyDown={() => toggleMenu()}
        className={navigationStyles.burgerIcon}
      ></button>
      <Link
        to={`/`}
        activeClassName={navigationStyles.active}
        onClick={() => hideMenu()}
      >
        Home
      </Link>
      <Link
        onClick={() => hideMenu()}
        to={`/blog`}
        activeClassName={navigationStyles.active}
        partiallyActive={true}
      >
        Blog
      </Link>
      <Link
        onClick={() => hideMenu()}
        to={`/download`}
        activeClassName={navigationStyles.active}
        partiallyActive={true}
      >
        Download
      </Link>
      <Link
        onClick={() => hideMenu()}
        to={`/showcase`}
        activeClassName={navigationStyles.active}
        partiallyActive={true}
      >
        Showcase
      </Link>
      {!burgerMenuOpen && <div className={socialLinks}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={social.instagram}
        >
          <FaInstagram />
        </a>
        <a target="_blank" rel="noopener noreferrer" href={social.youtube}>
          <FaYoutube />
        </a>
        <a target="_blank" rel="noopener noreferrer" href={social.patreon}>
          <FaPatreon />
        </a>
        <a target="_blank" rel="noopener noreferrer" href={social.twitter}>
          <FaTwitter />
        </a>
      </div>}

    </div>
  )
}

export default Navigation
